/* eslint react/prop-types: 0 */
import { graphql, Link } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import Img from 'gatsby-image';
import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { dataChristian, dataJulia } from '../hooks/dataPersons';

const sectionClass = "mb-16";

class TeamMember extends React.Component {
	render() {
		let primary = <a className="hover:text-primary" href={'mail'.concat('to:', this.props.person.email.props.children)}>{this.props.person.email}</a>
		let secondary = <a className="hover:text-primary" href={`tel:${this.props.person.phone}`}>{this.props.person.phone}</a>
		if (this.props.person?.karenz) {
			primary = 'derzeit in'
			secondary = 'Karenz'
		}
		return (
			<div className="relative">
				{this.props.person.bookURL !== undefined &&
					<a href={this.props.person.bookURL} className="absolute right-0 p-2 m-5 rounded-full text-white bg-copper overflow-hidden text-center z-20 hover:opacity-75">
						Termin<br />buchen
					</a>
				}
				<div className="group w-full min-w-22 sm:w-2/3 md:w-1/3 bg-gray content-center p-6">
					<Link to={this.props.link} className="group-hover:opacity-75">
						<div className="relative rounded-full overflow-hidden z-0 pb-full">
							<Img
								className="top-0 h-full w-full"
								style={{ position: 'absolute' }}
								fluid={this.props.image}
								alt={this.props.person.name}
							/>
						</div></Link>
					<div
						className="text-center -mt-10 z-10 relative rounded-lg bg-gray-300 overflow-hidden"
						style={{ hyphens: 'none' }}
					>
						<Link to={this.props.link}>
							<h1 className="text-lg text-white bg-gray-800">
								{this.props.person.firstName} <br /> {this.props.person.lastName}
							</h1></Link>
						{/* Mail encoded with http://www.katpatuka.org/pub/doc/anti-spam.html */}
						{primary}
						<br />
						{secondary}
					</div>
				</div>
			</div>
		);
	}
}

function IndexPage({ data }) {
	return (
		<Layout showHero={true}>
			<SEO
				keywords={[
					`Physiotherapie`,
					`Wien`,
					`1010`,
					`movendos`,
					`Manualtherapie`,
					`Manuelle Therapie`,
					`Sport`,
					`Verletzung`,
					`Sportverletzung`,
					`Knie`,
					`Schulter`,
					`Unfall`
				]}
				title="&Uuml;ber uns"
			/>
			<div id="team" style={{ height: '6rem', marginTop: '-6rem' }} />
			<section id="teamActive-1" className={`flex flex-wrap max-w-4xl justify-center mb-8`}>
				{/* <TeamMember link="/lena" person={dataLena} image={data.lena.childImageSharp.fluid} /> */}
				<TeamMember link="/julia" person={dataJulia} image={data.julia.childImageSharp.fluid} />
				<TeamMember link="/christian" person={dataChristian} image={data.chrisi.childImageSharp.fluid} />
			</section>
			<section id="teamActive-2" className={`${sectionClass}`}>
				<p className="mb-4">
					<b>Herzlich willkommen in unserer Physiotherapie-Praxis! Wir sind Julia und Christian und
					teilen die Leidenschaft für Bewegung und Gesundheit. Als Physiotherapeuten behandeln
					wir Patient:innen in der <a className='text-primary hover:text-copper' href="https://sportmedic.at/">Sportmedic</a>-Praxis von Dr. Brandner in 1010 Wien. Sowohl durch
					unsere Arbeit mit Patient:innen als auch eigene Erfahrung mit Schmerzen verstehen wir
					sehr gut, wie schmerzbedingte Bewegungseinschränkungen den Alltag und die
					Lebensqualität belasten können. Die Ursachen der Probleme sind häufig vielfältig. Unsere
					Erfahrung im Bereich der Orthopädie und Traumatologie hilft uns dabei, die Wurzel der
					Beschwerden zu finden.</b>
				</p>
				<p className="mb-4">
					Wir legen großen <b>Wert darauf, in Ruhe mit unseren Patient:innen über die körperlichen
					Probleme und Symptome zu sprechen</b>. Dadurch finden wir gemeinsam die passenden
					Behandlungsmethoden. Ein reger Austausch untereinander sowie mit Fachkolleg:innen über
					Behandlungsmöglichkeiten und die neuesten wissenschaftlichen Erkenntnisse ermöglichen
					es uns, <b>offen für verschiedene Ansätze</b> zu sein und stets Neues auszuprobieren.
				</p>
			</section>
			<section id="teamActive-3" className={`justify-center ${sectionClass}`}>
				<h1 className="text-lg text-primary mb-4">Manuelle Therapie und Trainingstherapie</h1>
				Spezialisiert sind wir auf Behandlungsmethoden der <a className='text-primary hover:text-copper' href="/behandlungen#manualtherapie">Manuellen Therapie</a> und
				<a className='text-primary hover:text-copper' href="/behandlungen#trainingstherapie">Trainingstherapie</a>, zu denen wir uns <b>fortlaufend weiterbilden</b>. Wir haben mit diesen
				Therapiemethoden bisher <b>vielversprechende Erfolgserlebnisse</b> bei unseren Patient:innen
				verzeichnen können. Doch wie schon zuvor erwähnt, befinden wir uns ständig im Austausch
				zu neuen Therapiemöglichkeiten, um für <b>dein individuelles, körperliches Befinden</b> die
				besten Behandlungsansätze zu finden.
			</section>
			<section id="teamActive-3" className={`justify-center ${sectionClass}`}>
				<h1 className="text-lg text-primary mb-4">Unser Credo zu Physiotherapie</h1>
				<p>
				In unserer Familie schätzen wir Bewegung sehr und wissen, dass manchmal ein kleiner
				Motivationsschub von außen notwendig ist, um den inneren Schweinehund zu besiegen. Wir
				sind nicht nur Physiotherapeuten, sondern auch <b>motivierende Begleiter</b> auf deinem Weg zur
				Genesung. Egal, ob du dich nach einer Verletzung, Operation oder aus anderen Gründen in
				Behandlung befindest, <b>gemeinsam schaffen wir es</b>, deine Schmerzen zu lindern und deine
				Bewegungsfreiheit zu fördern bzw. zurückzugewinnen. Bei Physiotherapie geht es schließlich
				darum, Lebensqualität (zurück) zu gewinnen.
				</p>
				<p>
				Gemeinsam identifizieren wir die Ursachen deiner Beschwerden und ermöglichen, die 
				<b> Freude an der Bewegung</b> (wieder) zu finden. Wir sind hier, um dir zu helfen.
				</p>
			</section>
			<section className={`${sectionClass}`}>
				<BackgroundImage
					Tag="div"
					className="flex h-48 items-end justify-center"
					fluid={data.content.childImageSharp.fluid}
					S
				>
					<blockquote
						style={{ textShadow: '0 0 5px black' }}
						className="text-white mb-6 sm:text-lg pl-4 font-serif"
					>
						&bdquo;Deinem Ziel bewegen wir uns gemeinsam entgegen.&rdquo;
					</blockquote>
				</BackgroundImage>
			</section>
		</Layout>
	);
}

export const query = graphql`
	fragment TeamImage on File {
		childImageSharp {
			fluid(maxWidth: 240) {
				...GatsbyImageSharpFluid_withWebp_tracedSVG
			}
		}
	}

	{
		julia: file(relativePath: { eq: "julia-square.jpg" }) {
			...TeamImage
		}
		chrisi: file(relativePath: { eq: "chrisi.jpg" }) {
			...TeamImage
		}
		lena: file(relativePath: { eq: "lena-square.jpg" }) {
			...TeamImage
		}
		content: file(relativePath: { eq: "physios_14.jpg" }) {
			childImageSharp {
				fluid(maxWidth: 1280) {
					...GatsbyImageSharpFluid_withWebp_tracedSVG
				}
			}
		}
	}
`;

export default IndexPage;
